/* Footer Section */
.footer{
    background-color: var(--black);
    padding: 0rem 5%;
}

/* Footer Box Container */
.footer .box-container{
    display: -ms-grid;  
    display: grid;
    -ms-grid-columns: (minmax(25rem, 1fr))[auto-fit];
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    gap: 4rem; 
    padding: 2rem 0;
} 

/* Footer Item Paragraph */
.footer-item p{
    color: var(--white); 
    font-size: 1.6rem;
    line-height: 1.6;
}

/* Footer Item Heading */
.footer-item h2{ 
    color: var(--white);
    font-size: 2.5rem;
    font-weight: 600;
    letter-spacing: 0.15rem;
    padding-bottom: 1rem;
}

/* Footer Item Info container Paragraph */
.footer-item .info p{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

/* Footer Item Info container Link */
.footer-item .info a{ 
    font-size: 1.6rem;
    color: var(--white);
}

/* Footer Item Info container Link, Hover effect */
.footer-item .links p:hover a{
    color: var(--main-color);
}

/* Footer Item Info container Icon */
.footer-item .links p .icon{
    color: var(--main-color);
    font-size: 1.8rem;
}

/* Footer Item Pages container */ 
.footer-item .pages{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 2rem;
}

/* Footer Item Connect continer Icon */
.footer-item .connect .icon{
    font-size: 1.6rem;
    color: var(--main-color);
    height: 1.8rem;
    width: 1.8rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

/* Footer Item Connect text with "gmail" class */
.footer-item .gmail{
    text-transform: none;
}

/* Footer Item Social container */
.footer-item .social{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
}

/* Footer Item Social link */
.footer-item .social a{
    display: inline-block;
    height: 4rem;
    width: 4rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 50%;
}

/* Footer Item Social Icon */
.footer-item .social a .icon{
    color: var(--white);
    font-size: 2rem;
}

/* Footer Item Social link Background */
.footer-item .social a:nth-child(1) {
    background: #25316D;
}

.footer-item .social a:nth-child(2) {
    background: #5CB8E4;
}

.footer-item .social a:nth-child(3) {
    background: #E80F88
}

.footer-item .social a:nth-child(4) {
    background: #1363DF;
}

/* Footer Content */
.footer .content{
    text-align: center;
    padding: 2rem 0;
    border-top: 0.2rem solid var(--white);
}

/* Footer Content Paragraph */
.footer .content p{
    font-size: 2rem;
    color: var(--white);
}

/* Footer Content Span */
.footer .content p span{
    color: var(--main-color);
}