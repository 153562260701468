/* Title container */
.shoplist-title {
  background-color: var(--secondary-color);
  color: var(--white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center; 
  -ms-flex-pack: center;
  justify-content: center;
  padding: 1rem 0.5rem;
  margin-bottom: 1rem;
}

/* Individual titles */
.shoplist-title h3 {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 10rem;
  flex: 1 1 10rem;
  font-size: 1.8rem;
  text-align: center;
}

/* First title */
.shoplist-title h3:first-child {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 20rem;
  flex: 1 1 20rem;
}