/* Counting Section */
.counting {
  width: 100%;
  padding: 3rem 5%;
  margin: 1rem 0;
  min-height: 15rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center; 
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 3rem;
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url("../../../assets/Background/Counter.jpg");
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url("../../../assets/Background/Counter.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

/* Counting Box */
.counting .box {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 25rem;
  flex: 1 1 25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

/* Counting Box Icon */
.counting .box .icon {
  color: var(--white);
  font-size: 5rem;
  width: 5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 1rem;
}

/* Counting Box Count */
.counting .box .count {
  font-size: 3.5rem;
  font-weight: 500;
  color: var(--main-color);
}

/* Counting Box Heading */
.counting .box h3 {
  font-size: 2rem;
  font-weight: 400;
  color: var(--white);
  padding-bottom: 1rem;
  position: relative;
}

/* Counting Box Heading Line */
.counting .box h3:after {
  content: "";
  position: absolute;
  bottom: -15%;
  left: 0%;
  right: 0%;
  width: 6rem;
  height: 6%;
  background: var(--main-color);
  display: inline-block;
  margin: auto;
}  