/* Button style */
.btn {
display: inline-block;
font-size: 28.8px;
font-size: 1.8rem;
font-weight: 500;
text-transform: uppercase;
letter-spacing: 1px;
padding: 24px 48px;
padding: 1.5rem 3rem;
position: relative;
overflow: hidden;
color: #e6e6e6;
background: var(--secondary-color);
-webkit-transition: all 0.5s ease-in-out; /* Cross-browser support for transition */
-moz-transition: all 0.5s ease-in-out;
-o-transition: all 0.5s ease-in-out;
transition: all 0.5s ease-in-out;
box-shadow: inset 0 0 0 4.5px rgba(255, 255, 255, 0.65); /* Adds a box shadow effect */
border-radius: 5px;
}

/* Button style, On hover */
.btn:hover {
box-shadow: inset 0 0 0 4.5px rgba(255, 255, 255, 0.25); /* Changes the box shadow effect on hover */
cursor: pointer;
}