/* searchTour section */
.searchTour {
  padding-bottom: 0;
}
 
/* search-tour form */
.search-tour { 
  background: var(--white);
  box-shadow: var(--box-shadow);
  padding: 2rem; 
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; 
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center; 
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap; 
  flex-wrap: wrap;
  gap: 1.5rem;
}

/* Box containers */
.search-tour .box {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 25rem; 
  flex: 1 1 25rem; 
}

/* Input field */
.search-tour .input-field {
  border: 0.1rem solid rgba(0,0,0,0.2);
  border-radius: 0.4rem;
  overflow: hidden;
  width: 100%;
  padding: 1rem;
  font-size: 1.7rem;
  color: var(--black);
  text-transform: none; 
  min-height: 4.5rem;
}

/* Focused input field */
.search-tour .input-field:focus {
  border-color: var(--main-color);
  box-shadow: 0 0 0 1px var(--main-color);
}
 
/* Input field placeholder */
.search-tour .box input::-webkit-input-placeholder {
  text-transform: capitalize;
}
.search-tour .box input::-moz-placeholder {
  text-transform: capitalize;
}
.search-tour .box input:-ms-input-placeholder {
  text-transform: capitalize;
}
.search-tour .box input::placeholder {
  text-transform: capitalize;
}

/* Submit button */
.search-tour .btn {
  width: 100%;
  display: block;
  text-align: center;
}


/*----- react-select styling -----*/

/* Select wrapper */
.custom-select .custom-select-prefix__control {
  border: 1px solid rgba(0,0,0,0.2) !important;
  font-size: 1.5rem;
  border-radius: 0.4rem;
  min-height: 4.5rem;
}

/* Focused Select wrapper */
.custom-select-prefix__control--is-focused {
  border-color: var(--main-color) !important;
  box-shadow: 0 0 0 1px var(--main-color) !important;
}

/* Select option */
.custom-select .custom-select-prefix__option {
  cursor: pointer;
  font-size: 1.5rem;
}

/* select option hovered */
.custom-select .custom-select-prefix__option:hover {
  color: var(--white);
  background-color: var(--main-color);
}

/* select option selected */
.custom-select .custom-select-prefix__option--is-selected {
  color: var(--white);
  background-color: var(--secondary-color);
}