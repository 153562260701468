/* Discount Banner Section */
.discount-banner {
  width: 100%;
  height: 80vh;
  padding: 3rem 5%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url("../../../assets/Background/Discount.jpg");
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url("../../../assets/Background/Discount.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

/* Discount Banner Content */
.discount-banner .content {
  width: 70rem;
}

/* Discount Banner Heading */
.discount-banner h2 {
  font-size: 5.5rem;
  color: var(--white);
} 

/* Discount Banner Heading Span */
.discount-banner h2 span {
  color: var(--main-color);
} 

/* Discount Banner Paragraph */
.discount-banner p {
  font-size: 1.5rem;
  letter-spacing: 0.15rem;
  font-weight: 500;
  color: var(--white);
  padding-top: 1rem;
  padding-bottom: 2rem;
  line-height: 1.4;
}  