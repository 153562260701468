/* Heading container */
.heading {
  padding-bottom: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; 
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* Heading container Span element */
.heading span {
  font-size: 2rem;
  letter-spacing: 0.2rem;
  display: inline-block;
  font-family: 'Fuzzy Bubbles', cursive;
  font-weight: bold;
  color: var(--main-color);
  margin-bottom: 0.5rem;
}

/* Heading container title */
.heading h2 {
  font-weight: bolder;
  font-size: 4rem;
  color: var(--secondary-color);
  text-transform: uppercase;
  font-family: "Raleway", sans-serif;
  letter-spacing: 0.1rem;
}

/* Override styles for Heading container, Within a linear background container */
.linear-bg .heading span,
.linear-bg .heading h2 {
  color: var(--white);
}