/* Destination item */
.destination-item {
  height: 30rem;
  overflow: hidden;
  position: relative;
}

/* Destination item content */
.destination-item .content {
  position: absolute;
  top: 0%; 
  left: 0%;
  width: 100%;
  height: 100%;
  background: -webkit-linear-gradient(rgba(33, 33, 33, 0.1), rgba(0, 0, 0, 0.5));
  background:         linear-gradient(rgba(33, 33, 33, 0.1), rgba(0, 0, 0, 0.5));
  padding: 1.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  overflow: hidden;
}

/* Destination item image container */
.destination-item .image {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

/* Image inside Destination item image container */
.destination-item img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

/* Destination item Total Tours */
.destination-item p {
  color: var(--white);
  background: var(--secondary-color);
  border-bottom-right-radius: 1.5rem;
  border-bottom: 0.3rem solid var(--main-color);
  font-size: 1.5rem;
  padding: 0.8rem 1rem;
  width: 8rem;
  text-align: center;
}

/* Destination item heading */
.destination-item h3 {
  color: var(--white);
  font-size: 3rem;
  font-weight: 500;
}

/* Destination item heading, Hover effect */
.destination-item:hover h3 {
  color: var(--main-color);
}