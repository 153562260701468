/* Tour single container */
.tour-single{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; 
    gap: 2rem;  
}   

/* Tour info container */
.tour-info{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 75rem;
    flex: 1 1 75rem;
}

/* Tour image container */
.tour-info .tour-image{
    height: 40rem;
    overflow: hidden;
    position: relative;
}

/* Tour image */
.tour-info .tour-image img{
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
}

/* Price tag on Tour image */
.tour-single .price{
    position: absolute;
    top: 75%;
    left: 0;
    background: var(--secondary-color);
    padding: 1rem 2rem;
    color: var(--white);
    font-size: 2rem;
    border-bottom-right-radius: 1.5rem;
    border-bottom: 3px solid var(--main-color);
}

/* Main heading in Tour info */
.tour-info .main-heading{
    display: inline-block;
    font-size: 4rem;
    font-weight: 600;
    color: var(--secondary-color);
    padding-top: 2rem;
}

/* Tour details container */
.tour-info .details{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 2rem;
    padding-bottom: 2rem;
}

/* Tour detail item */
.tour-info .details h3{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1rem;
}

/* Tour detail item icon */
.tour-info .details h3 .icon{
    font-size: 1.6rem;
    color: var(--main-color); 
} 

/* Tour detail item text */
.tour-info .details h3 span{
    color: var(--grey);
    font-size: 1.6rem;
    font-weight: 500;
}

/* Tour Rating */
.tour-info .rating{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 1rem;
} 
  
/* Tour Rating Icon */
.tour-info .rating .icon{
    font-size: 1.6rem;
    color: goldenrod;
}
  
/* Tour Rating text */
.tour-info .rating h5{
    font-size: 1.6rem;
    color: var(--black);
}

/* Tour info paragraphs */
.tour-info .content p{
    padding-bottom: 1rem;
    font-size: 1.5rem;
    color: var(--grey);
    line-height: 1.5;
}

/* Tour info sub-heading */
.tour-info .sub-heading{
    display: inline-block;
    font-size: 2.5rem;
    font-weight: 600;
    color: var(--black);
    padding-top: 2rem;
    padding-bottom: 0.5rem;
}

/* Tour details paragraphs */
.tour-details > p{
    padding-bottom: 1rem;
    font-size: 1.5rem;
    color: var(--grey);
    line-height: 1.5;
}

/* Tour details list */
.tour-details ul{
    list-style: none;
}

/* Tour details list items */
.tour-details ul li{
    padding: 1rem 0;
    font-size: 1.7rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; 
    gap: 1rem;
    border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
}

/* Tour details list items heading */
.tour-details ul li h5{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 20rem;
    flex: 1 1 20rem;
    font-size: 1.7rem;
    font-weight: 500;
    color: var(--black);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1rem;
}

/* Tour details list items icon */
.tour-details ul li .icon{
    font-size: 1.6rem;
    color: var(--main-color);
}

/* Tour details list items paragraph */
.tour-details ul li p{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 30rem;
    flex: 1 1 30rem;
    font-size: 1.6rem;
    color: var(--grey);
}

/* List container inside Tour details list items */
.tour-details .list{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 30rem;
    flex: 1 1 30rem;
    font-size: 1.6rem;
    color: var(--grey);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

/* Tour Plan Box-Container */
.tour-plan .box-container{
    padding-top: 1rem;
}

/* Plan item */
.plan-item{
    position: relative;
    padding-left: 2rem;
    border-left: 0.2rem solid var(--main-color);
    margin-left: 2rem;
}

/* Plan item dot */
.plan-item .dot{
    position: absolute;
    top: 0; 
    left: -2.5rem;
    height: 5rem;
    width: 5rem;
    padding: 1rem;
    border-radius: 50%;
    line-height: 5rem;
    text-align: center;
    font-size: 2rem;
    color: var(--white);
    background: var(--main-color);
}

/* Plan item content */
.plan-item .content{
    padding: 0rem 2rem;
    padding-bottom: 2rem;
}

/* Plan item heading */
.plan-item h3{
    font-size: 2.5rem;
    font-weight: 600;
    color: var(--black);
    padding-bottom: 1rem;
    padding-top: 0.5rem;
}

/* Plan item paragraphs */
.plan-item p{
    color: var(--grey);
    font-size: 1.6rem;
    line-height: 1.5;
}

/* Plan item list */
.plan-item ul{
    padding-left: 1rem;
}

/* Plan item list items */
.plan-item ul li{
    padding-bottom: 0.5rem;
    color: var(--grey);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; 
    gap: 1rem;
}

/* Plan item list items icon */
.plan-item ul li .icon{
    color: var(--main-color);
    font-size: 1rem;
}

/* Plan item list items text */
.plan-item span{
    color: var(--grey);
    font-size: 1.4rem;
}

/* Tour map iframe */
.tour-map iframe{
    height: 40rem;
    width: 100%;
    box-shadow: var(--box-shadow);
}

/* Tour gallery box container */
.tour-gallery .box-container{
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (minmax(25rem, 1fr))[auto-fit];
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    gap: 0.5rem;
}

/* Tour gallery images */
.tour-gallery img{
    height: 100%;
    width: 100%;
    cursor: pointer;
    box-shadow: var(--box-shadow);
}

/* Leave review container */
.leave-review{
    width: 100%;
    padding-top: 2rem;
} 

/* Booking tour container */
.booking-tour{
    padding: 2rem;
    background: var(--white);
    box-shadow: var(--box-shadow);
    text-align: center;
    border: var(--border);
}
  
/* Booking tour heading */
.booking-tour h3{ 
    font-size: 3rem;
    padding-bottom: 2rem;
    font-weight: 600;
    color: var(--secondary-color);
    text-transform: uppercase;
}

/* Booking tour input field container */
.booking-tour .input-field{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 1rem;
    margin: 0.7rem 0;
    border: 0.1rem solid rgba(0, 0, 0, 0.5);
    overflow: hidden;
}

/* Booking tour input field container label */
.booking-tour .input-field label{
    color: var(--white);
    background-color: var(--secondary-color);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 2rem;
    font-size: 1.8rem;
    cursor: pointer;
    outline: none;
}

/* Booking tour input field container label with class 'fa-comment' */
.booking-tour .input-field label.fa-comment{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -ms-flex-align: start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start;
}
  
/* Booking tour input field */
.booking-tour .box{
    width: 100%;
    background-color: transparent;
    font-size: 1.6rem;
    color: var(--secondary-color);
    padding: 1.5rem;
    text-transform: none;
}

/* Booking tour input field placeholder */
.booking-tour .box::-webkit-input-placeholder{
    text-transform: capitalize;
}
.booking-tour .box::-moz-placeholder{
    text-transform: capitalize;
}
.booking-tour .box:-ms-input-placeholder{
    text-transform: capitalize;
}
.booking-tour .box::placeholder{
    text-transform: capitalize;
}

/* Booking tour button */
.booking-tour button{
    width: 100%;
    margin-top: 1rem; 
    border-radius: 0.5rem;
}