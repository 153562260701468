/* Page title Background styles */
.page-title {
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5)),
              url("../../assets/PageTitle/PageTitle.jpg"); /* Background image with gradient overlay */
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5)),
              url("../../assets/PageTitle/PageTitle.jpg");
  min-height: 40rem;
  padding-top: 4rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; 
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

/* Title text */
.page-title h2 {
  color: var(--white);
  font-size: 5rem;
  font-weight: 500;
  padding-bottom: 1rem;
  text-transform: uppercase;
}

/* Homepage link */
.page-title .link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 1.5rem;
}

/* Homepage link icon */
.page-title .link a {
  height: 3rem;
  width: 3rem;
  font-size: 2.5rem;
  color: var(--white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

/* Homepage link icon, Hover effect */
.page-title .link a:hover {
  color: var(--main-color);
}

/* Angle Double Right icon */
.page-title .link .icon {
  height: 2rem;
  width: 2rem;
  color: var(--white);
}

/* Page name text */
.page-title .link span {
  font-size: 2rem;
  font-weight: 500;
  color: var(--main-color);
}  