/* Login info container */
.login form .info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0.5rem 0;
}

/* Login Remember container */
.login form .remember {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.5rem;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
}

/* Login Remember label */
.login form .remember label {
  font-size: 1.5rem;
  color: var(--grey);
  cursor: pointer;
}

/* Login Forgot container */
.login form .forgot {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 1rem 0;
}