/* Header Container */
.header {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 7rem;
  width: 100%;
  z-index: 1000;
  padding: 0rem 5%;
  background-color: transparent;
}

/* Header Active  */
.header.active {
  position: fixed;
  background-color: var(--secondary-color);
  box-shadow: var(--box-shadow);
}

/* Header Menu Button */
.header .menu-btn {
  font-size: 2rem;
  color: var(--white);
  cursor: pointer;
  display: none;
}

/* Hover Effect on Header Menu Button */
.header .menu-btn:hover {
  color: var(--main-color);
}

/* Header Navbar */
.header .navbar {
  display: flex;
  gap: 1.5rem;
  height: 100%;
}

/* Mobile Menu */
.mobile-menu {
  display: none;
}

/* Responsive Styles */
@media (max-width: 991px) {

  /* Header Menu Button (Visible on Mobile) */
  .header .menu-btn {
    display: block; 
  } 

  /* Mobile Menu (Visible on Mobile) */
  .mobile-menu {
    display: block;
  }

  /* Header Navbar (Hidden on Mobile) */
  .header .navbar {
    display: none;
  }
}  