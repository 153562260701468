/* Destination Intro */
.destination-single .des-intro {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 1rem;
}

/* Destination Intro Image container */
.des-info .des-intro .image {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 75rem;
  flex: 1 1 75rem;
  height: 45rem;
  overflow: hidden;
}

/* Destination Intro Image */
.des-info .image img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

/* Destination Details */
.destination-details {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 30rem;
  flex: 1 1 30rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 3rem;
  background-color: var(--secondary-color);
  box-shadow: var(--box-shadow);
}

/* Destination Detail Item Intro container */
.destination-details .detail-item .intro {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.7rem;
}

/* Destination Detail Item Icon */
.destination-details .detail-item .icon {
  height: 1.5rem;
  width: 1.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.4rem;
  color: var(--white);
}

/* Destination Detail Item Heading */
.destination-details .detail-item h3 {
  font-size: 2rem;
  font-weight: 600;
  color: var(--white);
}

/* Destination Detail Item Paragraph */
.destination-details .detail-item p {
  font-size: 1.6rem;
  font-weight: 400;
  color: #f7f7f7;
}

/* Destination Content */
.des-info .des-content {
  margin: 2rem 0;
}

/* Destination Single Main Heading */
.des-info .main-heading {
  display: inline-block;
  font-size: 4rem;
  font-weight: bold;
  color: var(--secondary-color);
  padding-bottom: 1rem;
}

/* Destination Single Paragraph */
.destination-single p {
  padding-bottom: 1rem;
  font-size: 1.5rem;
  color: var(--grey);
  line-height: 1.5;
}

/* Destination Single Sub Heading */
.destination-single .sub-heading {
  display: inline-block;
  font-size: 3rem;
  font-weight: 600;
  color: var(--black);
  padding-bottom: 1rem;
}

/* Destination Gallery */
.des-gallery .box-container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(33rem, 1fr))[auto-fit];
  grid-template-columns: repeat(auto-fit, minmax(33rem, 1fr));
  gap: 1rem;
}

/* Destination Gallery Image */
.des-gallery img {
  height: 25rem;
  width: 100%;
  object-fit: cover;
  cursor: pointer;
}

/* Destination Tours */ 
.des-tours {
  margin-top: 2rem; 
}

/* Destination Tours Box Container */
.des-tours .box-container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(33rem, 1fr))[auto-fill];
  grid-template-columns: repeat(auto-fill, minmax(33rem, 1fr));
  grid-gap: 1rem;
}  