/* Shop summary container */
.shop-summary {
  padding: 1.5rem;
  border: var(--border);
}
  
/* Summary item */
.summary-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 1rem 0;
  padding: 0.5rem 0;
}

/* Summary item boxes */
.summary-item .box {
  font-size: 2rem;
  color: var(--black);
}

/* Summary item Name box */
.summary-item .name {
  font-weight: bold;
}

/* Summary item Value box */
.summary-item .value {
  font-weight: 400;
  color: var(--grey);
}  