/* Logo container */
.logo{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1rem; 
}

/* Logo image */
.logo img{
    height: 3.5rem;
    -o-object-fit: cover;
       object-fit: cover;
}

/* Logo text */
.logo h3{
    font-size: 2.5rem;
    font-weight: 500;
    color: var(--white);
    letter-spacing: 0.1rem;
}