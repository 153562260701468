/* Sidebar heading container */
.sidebar-heading {
    margin-bottom: 0.5rem;
}

/* Sidebar heading title */
.sidebar-heading h2 {
    font-size: 3rem; 
    font-weight: 600;
    color: var(--secondary-color);
    position: relative;
}