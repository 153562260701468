/* Team member image container */
.team-item .image {
  height: 35rem;
  width: 100%;
  position: relative; 
  z-index: 0;
  overflow: hidden;
  border-bottom-right-radius: 5rem;
  border-bottom: 1rem solid var(--main-color);
}

/* Team member image */
.team-item .image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

/* Social media icon container */
.team-item .icon-container {
  position: absolute;
  top: -100%;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.5rem;
  background: rgba(20, 66, 114, 0.7);
}

/* Show Icon container on hover */
.team-item:hover .icon-container {
  top: 0%;
}

/* Social media icons */
.team-item .icon-container a {
  color: var(--white);
  font-size: 1.7rem;
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  border: 0.15rem solid var(--white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* Social media icons, Hover Effect */
.team-item .icon-container a:hover {
  color: var(--main-color);
  border-color: var(--main-color);
}

/* Team member content */
.team-item .content {
  margin-top: 1rem;
  padding: 1rem;
}

/* Team member name */
.team-item .content h3 {
  color: var(--secondary-color);
  font-size: 2.5rem;
}

/* Team member title */
.team-item .content p {
  color: var(--main-color);
  font-size: 1.6rem;
}