/* Blog item image */
.blog-item .image {
  height: 25rem;
  border-top: 0.7rem solid var(--secondary-color);
  border-top-left-radius: 6rem;
  border-bottom: 0.7rem solid var(--main-color);
  border-bottom-right-radius: 6rem;
  overflow: hidden;
} 

/* Blog item image element, inside the image container */
.blog-item .image img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

/* Blog item image element, On hover */
.blog-item:hover .image img {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
}

/* Blog item content */
.blog-item .content {
  padding-top: 1rem;
  padding-bottom: 2rem;
}

/* Blog item category label */
.blog-item .label {
  font-size: 1.5rem;
  padding: 0.8rem 1rem;
  display: inline-block;
  color: var(--white);
  background: var(--secondary-color);
  padding-left: 2rem;
  position: relative;
}

/* Imaginary element appearing before the Blog item category label */
.blog-item .label:before {
  content: " ";
  display: inline-block;
  height: 1.5rem;
  width: 1.5rem;
  position: absolute;
  top: 50%;
  left: -0.6rem;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background: var(--white);
  border-radius: 50rem;
}

/* Blog item Main heading */
.blog-item .content .main-heading {
  display: block;
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--black);
  line-height: 1.4;
  padding: 0.5rem 0;
}

/* Blog item Main heading, On hover */
.blog-item .content .main-heading:hover {
  color: var(--main-color);
}

/* Blog item Paragraph content */
.blog-item .content p {
  font-size: 1.6rem;
  color: var(--grey);
  line-height: 1.5;
}

/* Blog item details section */
.blog-item .details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}

/* blog item details section text */
.blog-item .details span {
  font-size: 1.4rem;
  color: var(--secondary-color);
}