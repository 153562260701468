/* Shop */
.shop {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 2rem;
}

/* Shop Container */
.shop .shop-container {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 75rem;
  flex: 1 1 75rem;
}

/* Product Container (Grid) */
.shop .product-container.grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(22rem, 1fr))[auto-fill];
  grid-template-columns: repeat(auto-fill, minmax(22rem, 1fr));
  grid-gap: 1rem;
}  