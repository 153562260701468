/* Shopping cart container */
.cart .shopping-cart{
    margin-bottom: 2rem;
    overflow-x: auto;
    overflow-y: hidden; 
    border: var(--border); 
} 

/* Shopping cart container Scrollbar */
.cart .shopping-cart::-webkit-scrollbar{ 
    height: 1rem;
}

/* Shopping cart inside Container */
.cart .shopping-cart .container{
    min-width: 90rem;  
}

/* Cart item */
.cart-item{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 1rem;
    gap: 3rem;
    border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
} 

/* Cart item last child */
.cart-item:last-child{
    border: none;
}

/* Cart item Box */
.cart-item .box{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 15rem;
        flex: 1 1 15rem;
    text-align: center;
    font-size: 2rem;
    font-weight: 400;
    color: var(--black);
}

/* Cart item Product Container */
.cart-item .product{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 25rem; 
        flex: 1 1 25rem;  
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
    gap: 1rem;
}

/* Cart item Image */
.cart-item img{
    height: 8rem;
    width: 8rem;
    -o-object-fit: cover;
       object-fit: cover;
    background-color:#f7f7f7;
}

/* Cart item Name */
.cart-item .name{
    color: var(--secondary-color);
    font-weight: 500;
}

/* Cart item Action Container */
.cart-item .action{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

/* Cart item Icon */
.cart-item .icon{
    font-size: 2rem;
    cursor: pointer;
    color: red;
}

/* Cart item Icon, Hover Effect */
.cart-item .icon:hover{
    color: var(--secondary-color);
}

/* Cart total */
.cart .cart-total{
    width: 45rem;
    margin-left: auto;
} 

/* Cart total Button */
.cart .cart-total .btn{
    width: 100%;
    text-align: center;
    margin: 1rem 0;
}