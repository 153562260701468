/* Shop header container */
.shop .shop-header {
  padding-bottom: 1rem; 
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 1rem;
}

/* Showing products count */
.shop .shop-header .showing {
  font-size: 1.6rem;
  color: var(--grey);
}

/* Styles buttons */
.shop .shop-header .styles {
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
}

/* Styles button links */
.shop .shop-header .styles a {
  font-size: 2rem;
  color: var(--black);
}

/* Styles button links, Hover */
.shop .shop-header .styles a:hover {
  color: var(--main-color);
}

/* Sorting type Select */
.shop .shop-header .sorting-type select {
  font-size: 1.4rem;
  color: var(--grey);
  background-color: transparent;
  border: var(--border);
  border-radius: 0.5rem;
  padding: 1rem;
}  