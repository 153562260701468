/* Quantity box Container  */
.quantity {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
  
/* Quantity Input field */
.quantity .input-text.qty {
  width: 3.5rem;
  height: 4rem;
  padding: 0 5px;
  text-align: center;
  background-color: transparent;
  border: 1px solid #ccc;
}

/* Minus and Plus Buttons */
.quantity.buttons_added .minus,
.quantity.buttons_added .plus {
  padding: 7px 10px 8px;
  height: 4rem;
  background-color: #ffffff;
  border: 1px solid #ccc;
  cursor: pointer;
}

/* Minus button */
.quantity.buttons_added .minus {
  border-right: 0;
  color: var(--black);
}

/* Plus button */
.quantity.buttons_added .plus {
  border-left: 0;
  color: var(--black);
}

/* Minus and Plus buttons, Hover Effect */
.quantity.buttons_added .minus:hover,
.quantity.buttons_added .plus:hover {
  background: var(--main-color);
  color: var(--white);
}

/* Remove default spin button appearance */
.quantity input::-webkit-outer-spin-button,
.quantity input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/* Remove focus outline for minus and plus buttons */
.quantity.buttons_added .minus:focus,
.quantity.buttons_added .plus:focus {
  outline: none;
}  