/* Contact section box container */
.contact .box-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; 
    box-shadow: var(--box-shadow);
}

/*----- 1- Google Map -----*/
.contact iframe {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 33rem;
    flex: 1 1 33rem;
}

/*----- 2- Contact Info -----*/
/* Contact info container */
.contact-info {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 33rem;
    flex: 1 1 33rem;
    background: var(--black);
    padding: 2rem 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

/* Contact info item */
.contact .contact-info .info-item {
    padding: 1rem;
    text-align: center;
}

/* Contact info item Heading */
.contact .contact-info .info-item h4 {
    font-size: 2rem;
    font-weight: 600;
    color: var(--main-color);
    padding-bottom: 0.5rem;
}

/* Contact info item Paragraph */
.contact .contact-info .info-item p {
    font-size: 1.6rem;
    color: var(--white);
    line-height: 1.6;
    text-transform: none;
    padding-bottom: 0.5rem;
}

/* Contact info item paragraph with "gmail" class */
.contact .contact-info .info-item p.gmail {
    text-transform: none;
}


/*----- 3- Contact Form -----*/
/* Contact form heading */
.contact h3{
    color: var(--secondary-color);
    font-size: 3rem;
    padding-bottom: 2rem;
    text-align: center;
}

/* Contact form */
.contact-form{  
    background: var(--white);  
    -webkit-box-flex: 1;
    -ms-flex: 1 1 33rem;
    flex: 1 1 33rem;
    padding: 1rem 2rem;
}

/* Contact form Input field */
.contact-form .box{
    width: 100%;
    font-size: 1.6rem;
    color: var(--secondary-color);
    text-transform: none;
    padding: 1rem 0.5rem;
    margin-bottom: 1rem;
    border-bottom: 0.15rem solid rgba(0, 0, 0, 0.1);
}

/* Contact form Input field, When focused */
.contact-form .box:focus {
    border-color: var(--main-color);
}

/* Contact form Input field Placeholder text */
.contact-form .box::-webkit-input-placeholder{
    text-transform: capitalize;
    color: var(--grey);
}
.contact-form .box::-moz-placeholder{
    text-transform: capitalize;
    color: var(--grey);
}
.contact-form .box:-ms-input-placeholder{
    text-transform: capitalize;
    color: var(--grey);
}
.contact-form .box::placeholder{
    text-transform: capitalize;
    color: var(--grey);
}

/* Contact form Input field Textarea */
.contact-form textarea.box{
    height: 15rem;
    resize: none;
}

/* Contact form Button */
.contact .contact-form button{
    margin-top: 1rem;
}

/* Contact form Alert container */
.contact .contact-form .alert{
    font-size: 2rem;
    color: var(--main-color);
    padding-left: 1rem;
    padding-top: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

/* Contact form Alert Message */
.contact .msg-alert{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 1rem;
}