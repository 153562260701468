/* Services Section */
.services .box-container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(33rem, 1fr))[auto-fit];
  grid-template-columns: repeat(auto-fit, minmax(33rem, 1fr));
  gap: 1rem;
}

/* Service Item */
.service-item {
  position: relative;
  z-index: 3;
  overflow: hidden;
  padding: 2rem;
  display: -webkit-box;
  display: -ms-flexbox; 
  display: flex;
  gap: 1.5rem;
  -webkit-transition: 0.5 linear ease;
  transition: 0.5 linear ease;
  box-shadow: var(--box-shadow);
}

/* Service Item, Hover Effect */
.service-item:hover {
  cursor: pointer;
}

/* Service Item, Before Style */
.service-item:before {
  content: "";
  position: absolute;
  top: -100%;
  left: 0%;
  width: 100%;
  height: 100%;
  background: var(--main-color);
  z-index: -1;
}

/* Service Item Before Style, Service Hover Effect */
.service-item:hover:before {
  -webkit-animation-name: example;
  animation-name: example;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

/* Keyframes for Service Item Before Animation */
@-webkit-keyframes example {
  0% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}

@keyframes example {
  0% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}

/* Service Item Icon */
.service-item .icon {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 10rem;
  flex: 1 1 10rem;
  font-size: 2rem;
  height: 4rem;
  width: 4rem;
  color: var(--white);
  background-color: var(--secondary-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* Service Item Content */
.service-item .content {
  text-align: left;
}

/* Service Item Heading */
.service-item h3 {
  font-size: 2.5rem;
  color: var(--black);
  padding-bottom: 0.5rem;
}

/* Service Item Heading, Service Hover Effect */
.service-item:hover h3 {
  color: var(--white);
}

/* Service Item Paragraph */
.service-item p {
  font-size: 1.5rem;
  color: var(--grey);
  line-height: 1.7;
}

/* Service Item Paragraph, Service Hover Effect */
.service-item:hover p {
  color: var(--white);
}  