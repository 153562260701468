/* Sidebar Post item */
.sidebar .post-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 1rem;
    margin-bottom: 8px;
    margin-bottom: 0.5rem;
}
  
/* Sidebar Post items Last child */
.sidebar .post-item:last-child {
    margin-bottom: 0;
}
  
/* Sidebar Post item Image */
.sidebar .post-item img {
    height: 128px;
    height: 8rem;
    width: 160px;
    width: 10rem;
    -o-object-fit: cover;
       object-fit: cover;
    background-color: var(--grey);
}

/* Sidebar Post item Link */
.sidebar .post-item a {
    display: inline-block;
    font-size: 25.6px;
    font-size: 1.6rem;
    font-weight: 600;
    color: var(--black);
}

/* Sidebar Post item Link, On hover */
.sidebar .post-item a:hover {
    color: var(--main-color);
}

/* Sidebar Post item Date */
.sidebar .post-item h3 {
    padding-top: 0.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 0.5rem;
    font-size: 1.4rem;
    color: var(--grey);
}

/* Sidebar Post item Date Icon */
.sidebar .post-item h3 .icon {
    color: var(--main-color);
}

/* Sidebar Post item Date Text */
.sidebar .post-item h3 span {
    font-weight: 400;
}