/* Tour item */
.tour-item {
  border-bottom: 0.7rem solid var(--main-color);
  border-bottom-right-radius: 6rem;
  background-color: var(--white);
  -webkit-box-shadow: var(--box-shadow);
          box-shadow: var(--box-shadow); 
}

/* Tour image container */
.tour-item .image {
  width: 100%;
  height: 28rem;
  overflow: hidden;
  position: relative;
}

/* Tour image */
.tour-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Scale image on hover */
.tour-item:hover img {
  scale: 1.02;
}

/* Tour price */
.tour-item .price {
  position: absolute;
  top: 75%;
  left: 0;
  color: var(--white);
  background: var(--secondary-color);
  padding: 1rem 2rem;
  font-size: 2rem;
  border-bottom-right-radius: 1.5rem;
  border-bottom: 0.3rem solid var(--main-color);
}

/* Tour content */
.tour-item .content {
  padding: 2rem;
}

/* Tour rating container */
.tour-item .rating {
  display: flex;
  gap: 1rem;
}

/* Tour rating icon */
.tour-item .rating .icon {
  font-size: 1.6rem;
  color: goldenrod;
}

/* Tour rating text */
.tour-item .rating h5 {
  font-size: 1.6rem;
  color: var(--black);
}

/* Tour name link */
.tour-item .content a {
  display: inline-block;
  font-size: 2.5rem;
  font-weight: 600;
  padding-bottom: 1rem;
  color: var(--black);
}

/* Tour name link, Hover Effect */
.tour-item .content a:hover {
  color: var(--main-color);
}

/* Tour details container */
.tour-item .details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 2rem;
}

/* Tour single detail */
.tour-item .details h3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 1rem;
}

/* Tour detail icon */
.tour-item .details h3 .icon {
  font-size: 1.6rem;
  color: var(--main-color);
}

/* Tour detail value */
.tour-item .details h3 span {
  color: var(--grey);
  font-size: 1.6rem;
  font-weight: 500;
}