/* Tours container */
.tours .box-container{
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (minmax(33rem, 1fr))[auto-fill];
        grid-template-columns: repeat(auto-fill, minmax(33rem, 1fr));
    grid-gap: 1rem; 
} 

/* Tours "no tour found" message */
.no-tour-found{
    text-align: center;
    font-size: 3rem;
    font-weight: bold;
    color: var(--secondary-color);
}