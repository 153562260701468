/* Aligning About content in the center */
.about .box-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center; 
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 4rem;
}

/* About content */
.about .content {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 40rem;
  flex: 1 1 40rem;
}

/* About Heading */
.about .content h3 {
  font-size: 3.5rem;
  color: var(--secondary-color);
  line-height: 1.3;
  padding-bottom: 1rem;
}

/* About Paragraph style */
.about .content p {
  font-size: 1.5rem;
  letter-spacing: 0.05rem;
  font-weight: 400;
  color: var(--grey);
  padding-bottom: 1rem;
  line-height: 1.5;
}

/* About Button */
.about .content .btn {
  margin-top: 1rem;
}

/* About image container */
.about .image {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 40rem;
  flex: 1 1 40rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 1rem;
  overflow: hidden;
}

/* About images */
.about .image img {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 18rem;
  flex: 1 1 18rem;
  height: 40rem;
  width: 100%;
  object-fit: cover;
}  