/* Faq accordion item */
.faq .accordion {
    margin-bottom: 1.5rem;
}  
  
/* Faq last accordion item */
.faq .accordion:last-child {
    margin-bottom: 0rem;  
}    
 
/* Faq accordion item heading */
.faq .accordion .accordion-heading {
    padding: 1.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center; 
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 1rem;
    cursor: pointer; 
    box-shadow: var(--box-shadow);
    border: 0.1rem solid var(--secondary-color);
}

/* Faq accordion item heading, On Accordion Expanded */
.faq .accordion.active .accordion-heading {
    background: var(--secondary-color);
}

/* Faq accordion item heading title */
.faq .accordion .accordion-heading h3 {
    font-size: 2rem;
    color: var(--black);
}

/* Faq accordion item heading title, On Accordion Expanded */
.faq .accordion.active .accordion-heading h3 {
    color: var(--white);
}

/* Faq accordion item heading icon */
.faq .accordion .accordion-heading .icon {
    font-size: 2rem;
    color: var(--white);
    background: var(--main-color);
    padding: 1rem;
    border-radius: 50rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

/* Faq accordion item content */
.faq .accordion-content {
    padding: 1.5rem 0.5rem;
    font-size: 1.5rem;
    line-height: 2;
    color: var(--grey);
    display: none;
}

/* Faq accordion item content, On Accordion Expanded */
.faq .accordion.active .accordion-content {
    display: block;
}