/* Product item container */
.product-item {
  overflow: hidden;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
} 

/* Image container */
.product-item .image { 
  position: relative;
}

/* Cart section */
.product-item .image .cart {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0;
}

/* Show cart section on hover */
.product-item:hover .image .cart {
  opacity: 1;
}

/* Cart Link */
.product-item .image a {
  font-size: 1.8rem;
  padding: 0.7rem 1.2rem;
  cursor: pointer;
  color: var(--white);
  background-color: var(--main-color);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 0.5rem;
}

/* Cart Link, Hover effect */
.product-item .image a:hover {
  background-color: var(--secondary-color);
}

/* Product image */
.product-item .image img {
  width: 100%;
  height: 25rem;
  -o-object-fit: cover;
     object-fit: cover;
  border: var(--border);
}

/* Product content */
.product-item .content {
  padding-top: 1rem;
  padding-bottom: 2rem;
  text-align: left;
}

/* Product name */
.product-item h3 {
  font-size: 2.2rem;
  font-weight: 600;
  color: var(--black);
}

/* Product name, Hover effect */
.product-item h3:hover {
  color: var(--main-color);
}

/* Product price */
.product-item .price {
  font-size: 1.8rem;
  font-weight: bold;
  color: var(--main-color);
}

/* Product Discounted price */
.product-item .price span {
  font-size: 1.4rem;
  font-weight: 400;
  text-decoration: line-through;
  color: var(--grey);
  padding-left: 0.2rem;
}

/* Product rating */
.product-item .rating {
  padding-top: 0.7rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
}

/* Product Rating icon */
.product-item .rating .icon {
  font-size: 1.5rem;
  color: var(--main-color);
}

/* Product Rating text */
.product-item .rating h5 {
  font-size: 1.5rem;
  color: var(--black);
}  