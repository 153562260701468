/* Testimonials section */
.testimonials{
    overflow: hidden;
}  

/* Testimonial slider container */
.testimonial-slider{ 
    overflow: hidden;
    position: relative;
}  

/* Testimonial slider swiper container */
.testimonial-slider .swiper{
    overflow: hidden; 
    padding: 0rem 6rem;
    padding-bottom: 5rem;
}

/* Testimonial slider next and previous buttons */
.testimonial-slider .swiper-button-next,
.testimonial-slider .swiper-button-prev{
    background-color: var(--secondary-color);
    color: var(--white);
    font-size: 1rem;
    height: 5rem;
    width: 5rem;
    padding: 2rem;
}

/* Testimonial slider next button */
.testimonial-slider .swiper-button-next{
    right: 0rem;
}

/* Testimonial slider previous button */
.testimonial-slider .swiper-button-prev{
    left: 0rem;
}

/* Testimonial slider next and previous buttons arrow icon */
.testimonial-slider .swiper-button-next:after,
.testimonial-slider .swiper-button-prev:after {
    font-size: 2rem;
    font-weight: bold;
    color: var(--white);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center; 
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; 
}
 
/* Testimonial slider next and previous buttons, Hover Effect */
.testimonial-slider .swiper-button-next:hover,
.testimonial-slider .swiper-button-prev:hover {
    color: var(--white);
    background-color: var(--main-color);
}

/* Testimonial slider pagination bullets */
.testimonials .swiper-pagination-bullet{
    background: var(--secondary-color);
    opacity: 0.9;
}

/* Testimonial item */
.testi-item{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 2rem;
}

/* Testimonial image container */
.testi-item .image{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 30rem;
    flex: 1 1 30rem;
    height: 40rem;
    position: relative;
    padding-top: 2rem;
}

/* Testimonial image */
.testi-item .image img{
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
}

/* Testimonial image quote icon */
.testi-item .quote-left{
    position: absolute;
    height: 6rem;
    width: 6rem;
    font-size: 2.5rem;
    top: 0;
    right: -2rem;
    z-index: 6;
    background: var(--main-color);
    color: var(--white);
    padding: 2rem;
    border-radius: 50rem;
}

/* Testimonial item content */
.testi-item .content{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 50rem;
    flex: 1 1 50rem; 
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

/* Testimonial item paragraph */
.testi-item p{
    font-size: 1.8rem;
    line-height: 1.6;
    color: var(--grey);
    padding-bottom: 1.5rem;
}

/* Testimonial item name */
.testi-item .content h3{
    font-size: 2rem;
    color: var(--main-color);
}

/* Testimonial item title */
.testi-item .content h5{
    color: var(--grey);
    font-size: 1.6rem;
    font-weight: 400;
}

/* Testimonials section Heading (On the homepage) */
.homepage .testimonials .heading span,
.homepage .testimonials .heading h2{
    color: var(--white);
}

/* Testimonials section (On the homepage) */
.homepage .testimonials{
    background: -webkit-linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url("../../../assets/Background/Testimonials.jpg");
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url("../../../assets/Background/Testimonials.jpg");
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
} 

/* Testimonials section pagination bullet (On the homepage) */
.homepage .testimonials .swiper-pagination-bullet{
    background: var(--white);
    opacity: 0.7;
} 

/* Testimonial item paragraph (On the homepage) */
.homepage .testimonials .testi-item p{
    color: var(--white);
}

/* Testimonial item title (On the homepage) */
.homepage .testimonials .testi-item .content h5{
    color: var(--white);
}