/* Gallery item */
.gallery-item{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 33rem;
    flex: 1 1 33rem;
    overflow: hidden;
    position: relative;
    height: 30rem;
} 

/* Gallery item double-sized */
.gallery-item.double{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 40rem;
    flex: 1 1 40rem;
}

/* Gallery item image */
.gallery-item img{
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
}

/* Gallery item content overlay */
.gallery-item .content{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    outline: 0.1rem solid var(--white);
    outline-offset: -1rem;
    opacity: 0;
}

/* Gallery item content overlay, Hover effect */
.gallery-item:hover .content{
    opacity: 1;
}

/* Gallery item expand icon */
.gallery-item a .icon{
    height: 4rem;
    width: 4rem;
    font-size: 1.6rem;
    padding: 1rem;
    color: var(--white);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 0.15rem solid var(--white);
}

/* Gallery item expand icon, Hover effect */
.gallery-item a .icon:hover{
    cursor: pointer;
    background-color: var(--main-color);
    border-color: var(--main-color);
}

/* Gallery item title */
.gallery-item .content h3{
    font-size: 2.5rem;
    font-weight: 600;
    color: var(--white);
    padding-top: 1rem;
}