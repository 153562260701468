/* checkout section Heading */
.checkout .heading{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start;
    padding-bottom: 1rem;
}

/* checkout section Heading title */
.checkout .heading h2{
    font-size: 3rem;
} 

/* Checkout item */
.checkout-item{  
    -webkit-box-flex: 1;
    -ms-flex: 1 1 40rem;
    flex: 1 1 40rem;
    margin-bottom: 2rem;
}

/* Checkout section, Box-1 container */
.checkout .box-1{
    display: -webkit-box;
    display: -ms-flexbox; 
    display: flex;
    -ms-flex-wrap: wrap; 
    flex-wrap: wrap;
    gap: 2rem;
}

/*----- 1- Payment Method -----*/
/* Payment methods Box container */
.payment-methods .box-container {
    padding: 2rem;
    border: var(--border);
}

/* Payment method item */
.payment-methods .item {
    width: 100%;
    margin-bottom: 1.5rem;
}

/* Payment method label */
.payment-methods .item label {
    cursor: pointer;
    font-size: 2rem;
    color: var(--black);
}

/* Payment method input */
.payment-methods .item input {
    margin-right: 0.5rem;
}

/* Selected/Highlighted payment method label */
.payment-methods .item input:checked + label,
.payment-methods .item label:hover {
    color: var(--main-color);
}

/* Payment method body */
.payment-methods .item .payment-body {
    display: none;
}

/* Payment method body paragraph */
.payment-methods .item .payment-body p {
    font-size: 1.6rem;
    line-height: 2;
    color: var(--grey);
    padding-top: 1rem;
}

/* Show payment method body when input is checked */
.payment-methods .item > input:checked ~ .payment-body {
    display: block;
}

/* Active payment method body */
.payment-body.active {
    display: block;
} 

/* Checkout section cart total */
.checkout .cart-total{
    width: 50rem;
}

/* Checkout section Button */
.checkout .btn{
    width: 50rem;
    text-align: center;
}